@font-face {
    font-family: 'Roboto';
    src: local('Roboto-Regular'), url('../fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
}

body {
    font-family: 'Roboto' !important;
	background-color: #f1f1f1;
}

h1 {
	font-weight: 700;
	color:rgba(36, 56, 110, 1);
}

h2 {
	font-size: 15px;
	font-weight: 600;
	color: rgba(32, 102, 173, 1);
}

h3 {
	font-size: 12px;
	font-weight: 400;
	color: rgba(32, 102, 173, 1);
}

.badge-sml {
	font-size: 8px;
	background-color: rgba(84, 149, 207, 1);
	color: #fff;
	margin-right: 3px;
	vertical-align: middle;
}

.badge-med {
	font-size: 10px;
	background-color: rgba(84, 149, 207, 1);
	color: #fff;
	margin-right: 3px;
	vertical-align: middle;
}

p {
	font-size: 12px;
	font-weight: 400;
	color: rgba(36, 56, 110, 1);
    margin: 0;

}

.form-label {
	font-size: 12px;
	font-weight: 600;
	color: rgba(0, 51, 102, 1);

}

.main-button {
	background-color: rgba(38, 105, 172, 1);
	color: #fff;
	border-radius: 20px;
    padding: 10px;
    font-size: 14px;
    font-weight: 600;
}

.action-button {
	border-color: rgba(32, 102, 173, 1);
	color: rgba(32, 102, 173, 1);
	font-size: 14px;
	font-weight: 600;
	border-radius: 20px;
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.location-button {
	background-color: rgba(84, 149, 207, 1);
	color: #fff;
	border-radius: 20px;
    padding: 10px;
    font-size: 12px;
    font-weight: 600;
}

.contact-info {
	color: #fff;
	font-size: 14px;
	margin-right: 0.9em;
}

.bg-main {
	background-color: #2167AE;
}

.color-main {
	color: rgba(36, 56, 110, 1);
}

.mt-6{margin-top:5rem;}

.container-navbar {


}

.home-tab-container {
	border: 1px solid rgba(32, 102, 173, 1);
	border-radius: 20px;
	padding: 3px;
}

.home-tab-container .tab-menu {
	font-size: 12px;
	font-weight: 600;
	padding: 10px;
	border-radius: 16px;
	background-color: transparent;
	color: rgba(32, 102, 173, 1);
	text-align: center;
	border-width: 0;
}

.home-tab-container .tab-menu.active{
	color: #fff;
	background-color: rgba(144, 190, 226, 1);
}

.home-menu-container {
	border: 1px solid rgba(196, 196, 196, 1);
	padding: 5px;
	border-radius: 16px;
}

.home-menu-container .image {
	border-radius: 12px;
	overflow: hidden;
}

.home-menu-container .action {
	border-radius: 12px;
	padding: 10px;
	color: #fff;
	background-color: rgba(33, 103, 174, 1);
	margin-top: 5px;
	text-align: center;
	font-size: 8px;
	font-weight: 600;
}

.advertisement-container {
	border-radius: 16px;
	overflow: hidden;
	padding: 0!important;
}

.article-container {
	border-radius: 16px;
	overflow: hidden;
	padding: 0!important;
	width: 135px;
}

.article-container .title {
	background-color: #fff;
	padding: 10px;
	font-size: 10px;
	font-weight: 400;
	height: 100%;
}

.recommendation-container {
	border-radius: 16px;
	padding: 0!important;
	width: 120px;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
	border: 1px solid rgba(217, 217, 217, 1);
	overflow: hidden;
}

.recommendation-container .image {
	height: 90px;
	overflow: hidden;
}

.recommendation-container .title {
	background-color: #fff;
	padding: 10px;
	height: 100%;
}

.table-responsive {
	height: 100%;
}

.form-filter {
    padding: 10px 25px;
    background-color: #fff;
    border-radius: 16px;
}

.form-filter select {
    display: block;
    width: 100%;
    border: 0;
    font-size: 12px;
    font-weight: 600;
    color: rgba(33, 103, 174, 1);
    background-color: transparent;
}

.item-list {
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 1rem;
    padding: 10px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.item-list img{
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(196, 196, 196, 1);
    aspect-ratio: 1;
}

.detail-container {
	background-color: #fff;
	padding-top: 1rem;
	padding-bottom: 1rem;
	margin-top: -20px;
	border-radius: 16px 16px 0 0;
}

.select__multi-value__label {
	background-color: rgba(84, 149, 207, 1);
	color: #fff!important;
	border-radius: 10px 0 0 10px!important;
}	

.select__multi-value__remove {
	background-color: rgba(84, 149, 207, 1);
	color: #fff!important;
	border-radius: 0 10px 10px 0!important;
}

.select__control {
	border-radius: 16px!important;
	font-size: 12px!important;
}

.contact-container {
	padding: 1rem;
	margin-bottom: 1rem;
	background-color: #fff;
	border-radius: 16px;
}

.contact-container-blue {
	padding: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(36, 56, 110, 1);
	border-radius: 16px;
}

@media (min-width: 992px) {
.navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
	flex-direction: row-reverse;
	}
}

.navbar-toggler { 
	outline: none!important;
    border: none!important; 
	box-shadow: none;
}

.navbar-toggler.collapsed .icon-close {
	display: none;
}
  
.navbar-toggler.collapsed .hamburger-menu {
	display: inline-block;
}
  
.navbar-toggler  .hamburger-menu {
	display: none;
}